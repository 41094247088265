import Iconify                 from './Iconify'
import { IconButton, Tooltip } from '@mui/material'
import React, { useState }     from 'react'
import { useTranslation }      from 'react-i18next'

export default function CopyToClipboardWidget({ value }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return <Tooltip open={open} title={t('Copied to clipboard!')}>
    <IconButton
      aria-label="copy token"
      onClick={(e) => {
        navigator.clipboard.writeText(value).then(() => {
          setOpen(() => true)
          setTimeout(() => {
            setOpen(() => false)
          }, 1000)
        });
      }}
    >
      <Iconify icon={'material-symbols:content-copy'} sx={{ height: 24, width: 24 }} />
    </IconButton>
  </Tooltip>
}