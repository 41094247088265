import * as React         from 'react';
import PropTypes          from 'prop-types';
import Button             from '@mui/material/Button';
import Dialog             from '@mui/material/Dialog';
import DialogActions      from '@mui/material/DialogActions';
import DialogContent      from '@mui/material/DialogContent';
import DialogContentText  from '@mui/material/DialogContentText';
import DialogTitle        from '@mui/material/DialogTitle';
import Slide              from '@mui/material/Slide';
import { useTranslation } from 'react-i18next'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertSimpleDialog(props) {

  const { t } = useTranslation()
  const { onClose, open, title, message, ...other } = props;

  const handleCancel = () => onClose(false)
  const handleOk = () => onClose(true)

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCancel}
      aria-describedby="alert-dialog-slide-description"
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('Cancel')}</Button>
        <Button onClick={handleOk}>{t('OK')}</Button>
      </DialogActions>
    </Dialog>
  );
}

AlertSimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};