import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container, FormControl, InputLabel,
  Link, MenuItem, Select,
  Stack,
  Typography
}                                from '@mui/material'
import { useApi }                from '../utils/api'
import { Link as RouterLink }    from 'react-router-dom'
import { useTranslation }        from 'react-i18next'
import Page                      from '../ui/components/Page'
import BaseTable, { BaseSearch } from '../ui/components/base-table'
import Iconify                   from '../ui/components/Iconify'
import { BaseBreadcrumbs }       from '../ui/components/BaseBreadcrumbs'

export default function UsersPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [forceReloadCounter, setForceReloadCounter] = useState(0)
  const [selectedUserType, setSelectedUserType] = useState('')

  useEffect(() => {
    setForceReloadCounter(forceReloadCounter + 1)
  }, [selectedUserType])

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'name', headerName: t('Name'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/users/${cell.row.id}`}
        >
          {cell.row.name}
        </Link>
      )},
    { field: 'type', headerName: t('Type'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => t(`user-type-${cell.row.type}`) },
    { field: 'city', headerName: t('City'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => cell.row.city?.name ?? '---' },
    { field: 'email', headerName: t('Email'), flex: 1, sortable: false, filterable: false },
  ];

  return (<Page title={t('Users')}>
    <Container>
      <BaseBreadcrumbs key={'breadcrumb-users'}
                       title={t('Users')}
                       items={[]}/>
      <Stack direction="row" alignItems="center" mb={2} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Users')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/users/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New user')}
        </Button>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} mb={3} spacing={2}>
        <FormControl variant={'standard'} sx={{ minWidth: 120 }}>
          <InputLabel id="label-user-type">{t('User type')}</InputLabel>
          <Select
            labelId="label-user-type"
            id="select-user-type"
            value={selectedUserType}
            onChange={(e) => {
              setSelectedUserType(e.target.value)
            }}
            label={t('User type')}
          >
            <MenuItem value="">
              <em>{t('All')}</em>
            </MenuItem>
            <MenuItem value={'admin'}>{t('user-type-admin')}</MenuItem>
            <MenuItem value={'owner'}>{t('user-type-owner')}</MenuItem>
            <MenuItem value={'controlling'}>{t('user-type-controlling')}</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        forceReloadCounter={forceReloadCounter}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.user.list(query, [selectedUserType], page, pageSize)
          } catch (e) {
            console.log('Failed to load users', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}