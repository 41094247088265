import Card       from '@mui/material/Card'
import { Stack }  from '@mui/material'
import Iconify    from '../ui/components/Iconify'
import Typography from '@mui/material/Typography'
import PropTypes  from 'prop-types'

const EmptyListWidget = ({title}) => {
  return <Card component={Stack} direction={'column'} alignItems="center" spacing={3} sx={{
    px: 2,
    py: 3,
    borderRadius: 2,
  }}>
    <Iconify icon={'material-symbols:avg-time-outline'} color={'primary.light'} sx={{ height: 48, width: 48 }} />
    <Typography variant={'h4'} color={'text.disabled'}>{title}</Typography>
  </Card>
}
EmptyListWidget.propTypes = {
  title: PropTypes.string,
};

export default EmptyListWidget