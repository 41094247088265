import * as React             from 'react'
import Button                 from '@mui/material/Button'
import TextField              from '@mui/material/TextField'
import Dialog                 from '@mui/material/Dialog'
import DialogActions          from '@mui/material/DialogActions'
import DialogContent          from '@mui/material/DialogContent'
import DialogContentText      from '@mui/material/DialogContentText'
import DialogTitle            from '@mui/material/DialogTitle'
import PropTypes              from 'prop-types'
import { useTranslation }     from 'react-i18next'
import { DateTime, Duration } from 'luxon'
import { Alert }              from '@mui/material'
import { useApi }             from '../utils/api'
import { useSnackbar }        from 'notistack'

export default function CityServiceChangeScheduleDialog({ getFormData, onResult, cityServiceId }) {

  const api = useApi()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [date, setDate] = React.useState(DateTime.now().plus(Duration.fromObject({ days: 1 })).toFormat('yyyy-MM-dd'))

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
    if (!!onResult){
      onResult()
    }
  };

  const onConfirm = async () => {
    setError(() => null)
    if (DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day') < DateTime.now().startOf('day')) {
      setError(() => t('Scheduled time must be in future'))
      return
    }
    let data = getFormData()
    data.scheduled = date
    try {
      await api.cityService.scheduleChange(cityServiceId, data)
      enqueueSnackbar(t('Change is scheduled'), {
        variant: 'success'
      })
      handleClose()
    } catch (e) {
      enqueueSnackbar(t('Failed to schedule city service change'), {
        variant: 'error'
      })
    }
  }

  return (
    <React.Fragment>
      <Button type={'button'} color={'secondary'} sx={{ ml: 2 }} variant="outlined" onClick={(e) => {
        e.preventDefault()
        handleClickOpen()
      }}>
        {t('Schedule change')}
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
      >
        <DialogTitle>{t('Schedule change')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('scheduleChangeDesc')}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="date"
            name="date"
            label={t('Change active from')}
            type="date"
            fullWidth
            variant="standard"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          {error && <Alert sx={{ mt: 2 }} severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button color={'error'} onClick={handleClose}>Cancel</Button>
          <Button onClick={onConfirm}>{t('Confirm')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CityServiceChangeScheduleDialog.propTypes = {
  getFormData: PropTypes.func.isRequired,
}