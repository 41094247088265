import { useCallback, useEffect, useState } from 'react'
import { useApi }                           from './api'

let cities
export default function useCities () {
  const api = useApi()
  const [local, setLocal] = useState(cities)
  const [reload, setReload] = useState(0)

  const reloadFnc = useCallback(() => {
    cities = undefined
    setReload(reload => reload + 1)
  }, [])

  useEffect(() => {
    async function load () {
      cities = (await api.city.list()).data
      setLocal(cities)
    }

    if (cities === undefined) {
      load()
    }
  }, [reload])

  return [local, reloadFnc]
}