import { Trans, useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Box, Button,
  Collapse,
  Container,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography
}                                from '@mui/material'
import BaseTable, { BaseSearch } from '../ui/components/base-table'
import { useApi }                     from '../utils/api'
import React, { useEffect, useState } from 'react'
import { fDateTime }                  from '../utils/textutils'
import Page                              from '../ui/components/Page'
import { BaseBreadcrumbs }               from '../ui/components/BaseBreadcrumbs'
import { Link as RouterLink, useParams } from 'react-router-dom'
import palette                           from '../ui/theme/palette'
import Iconify                           from '../ui/components/Iconify'
import ShortFormControl
                                         from '../ui/components/short-form-control'
import { DateTime }                      from 'luxon'
import { useSnackbar }                   from 'notistack'

export default function MessagesPage () {

  const { t } = useTranslation()
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [filterOpen, setFilterOpen] = useState(true)
  const { query, from, to,  } = useParams()
  const [filter, setFilter] = useState({
    dateFrom: from ?? DateTime.now().toFormat('yyyy-MM-dd'),
    dateTo: to ?? DateTime.now().toFormat('yyyy-MM-dd'),
    query: query ?? '',
  })
  const [shownFilter, setShownFilter] = useState({
    dateFrom: from ?? DateTime.now().toFormat('yyyy-MM-dd'),
    dateTo: to ?? DateTime.now().toFormat('yyyy-MM-dd'),
    query: query ?? '',
  })
  const [baseTableQuery, setBaseTableQuery] = useState('{}')

  const onSearchSubmit = (e) => {
    e.preventDefault()
    setBaseTableQuery(JSON.stringify(filter))
    setShownFilter(filter)
  }

  const makeFilterTitle = () => {
    const reqDateFrom = (shownFilter.dateFrom ? DateTime.fromFormat(shownFilter.dateFrom, 'yyyy-MM-dd') : DateTime.now()).toFormat('dd.MM.yyyy')
    const reqDateTo = (shownFilter.dateTo ? DateTime.fromFormat(shownFilter.dateTo, 'yyyy-MM-dd') : DateTime.now()).toFormat('dd.MM.yyyy')
    const reqQuery = shownFilter.query ? shownFilter.query : "''"
    return <Trans i18nKey={'messagesFilterHighlight'} values={{ from: reqDateFrom, to: reqDateTo, query: reqQuery }} />
  }

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            variant="body2"
            to={`/messages/${cell.row.id}`}
          >
            {cell.row.id}
          </Link>
        )
    }},
    { field: 'telFrom', headerName: t('Tel. From'), flex: 1, sortable: false, filterable: false },
    { field: 'telTo', headerName: t('Tel. To'), flex: 1, sortable: false, filterable: false },
    {
      field: 'sent',
      headerName: t('Sent/Received'),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (cell) => {
        const datetime = cell.row.sent ?? cell.row.received
        return datetime ? fDateTime(datetime) : t(`deliverystatus-${cell.row.deliveryStatus}`)
      }
    },
    { field: 'content', headerName: t('Content'), flex: 3, sortable: false, filterable: false },
  ]

  useEffect(() => {
    setBaseTableQuery(JSON.stringify(filter))
  }, [])

  return (<Page title={t('Messages')}>
    <Container>
      <BaseBreadcrumbs key={'breadcrumb-mesages'}
                       title={t('Messages')}
                       items={[]}/>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2} alignItems={'baseline'} mb={2}>
            <Typography variant={'h6'}>{t('Messages')}</Typography>
            <Typography variant="body1" sx={{ color: palette.text.disabled }}>
              {makeFilterTitle()}
            </Typography>
            <Box flexGrow={1}/>
            <IconButton onClick={(e) => setFilterOpen(prev => !prev)}>
              <Iconify icon={filterOpen ? 'material-symbols:filter-alt-outline' : 'material-symbols:filter-alt'}/>
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <Collapse in={filterOpen}>
        <Grid container component={'form'} onSubmit={onSearchSubmit} spacing={2} rowSpacing={2} direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'} mb={2}>
          <Grid item xs={6} sm={'auto'}>
            <ShortFormControl title={t('From')} handleChange={(name) => (e) => { setFilter((prev) => ({
              ...prev,
              dateFrom: e.target?.value
            })) }} value={filter.dateFrom} name={'from'} inputProps={{ type: 'date' }} />
          </Grid>
          <Grid item xs={6} sm={'auto'}>
            <ShortFormControl title={t('To')} handleChange={(name) => (e) => { setFilter((prev) => ({
              ...prev,
              dateTo: e.target?.value
            })) }} value={filter.dateTo} name={'to'} inputProps={{ type: 'date' }} />
          </Grid>
          <Grid item xs={12} sm={'auto'} sx={{ minWidth: { sm: '200px' } }}>
            <ShortFormControl title={t('Search...')} handleChange={(name) => (e) => { setFilter((prev) => ({
              ...prev,
              query: e.target?.value
            })) }} value={filter.query} name={'query'} inputProps={{ type: 'text' }} />
          </Grid>
          <Grid item xs={12} sx={{ maxWidth: { sm: '200px' } }}>
            <Button fullWidth type={'submit'} variant="outlined">{t('SearchAction')}</Button>
          </Grid>
        </Grid>
      </Collapse>
      <BaseTable
        columns={columns}
        query={baseTableQuery}
        loadCallback={async (query, page, pageSize) => {
          const queryObject = JSON.parse(query)
          const dateFrom = queryObject.dateFrom?.replaceAll('-','')
          const dateTo = queryObject.dateTo?.replaceAll('-','')
          try {
            return await api.message.list(null, dateFrom, dateTo, queryObject.query, page, pageSize)
          } catch (e) {
            console.log('Failed to load categories', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)
}