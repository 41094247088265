import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel, MenuItem,
  Paper, Select,
  Switch,
}                                 from '@mui/material'
import { useTranslation }         from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useApi }                 from '../utils/api'
import { useSnackbar }            from 'notistack'
import AlertSimpleDialog          from '../ui/components/alert-simple-dialog'
import Page                       from '../ui/components/Page'
import { BaseBreadcrumbs }        from '../ui/components/BaseBreadcrumbs'
import { ParkingAppContext }      from '../App'

export default function UserPage() {

  const api = useApi()
  const navigate = useNavigate()
  const { userId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [showPasswordPanel, setShowPasswordPanel] = useState(isNaN(userId))
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
  const [cities, setCities] = useState([])
  const { user } = useContext(ParkingAppContext)
  const isAdmin = user.type === 'admin'

  useEffect(() => {
    (async () => {
      if (!isNaN(userId)) {
        try{
          let user = await api.user.detail(userId)
          setValues({
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            cityId: '',
            type: '',
            ...user
          })
        } catch (e){
          enqueueSnackbar(t('Can\'t load user'), {
            variant: "error"
          })
        }
      }
    })()
  }, [userId])
  useEffect(() => {
    api.city.list(null, 0, 1000).then(({ data }) => setCities(data))
  }, [])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.user.delete(userId).then(() => {
        enqueueSnackbar(t('User deleted'), {
          variant: 'success'
        })
        navigate('/users')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete user'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = { ...values }
    if (!showPasswordPanel) {
      // if the password is not changed, ignore it
      delete data.password
      delete data.confirmPassword
    }
    try {
      let user = await api.user.save(data)
      navigate(`/users/${user.id}`)
      enqueueSnackbar(t('User saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save user'), {
        variant: 'error'
      })
    }
  }

  return (
    <Page title={t('User')}>
      <Container container component="main">
        <BaseBreadcrumbs key={'breadcrumb-user'}
                         title={values?.name ?? t('User')}
                         items={[
                           { title: t('Users'), href: `/users` }
                         ]}/>
        <Grid component={'form'} onSubmit={onSubmit} item xs={12}>
          <Paper sx={{ p: 2, width: '100%' }}>
            <Grid container sx={{ width: '100%' }} spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="user-name">{t('Name')}</InputLabel>
                  <Input
                    id="user-name"
                    value={values.name}
                    onChange={handleChange('name')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="user-email">{t('Email')}</InputLabel>
                  <Input
                    id="user-email"
                    value={values.email}
                    disabled={!isNaN(userId)}
                    onChange={handleChange('email')}
                  />
                </FormControl>
              </Grid>
              {isAdmin && <>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="type-label">{t('Type')}</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type-select"
                    value={values?.type ?? ''}
                    label={t('Type')}
                    onChange={handleChange('type')}
                  >
                    <MenuItem key={'type-admin'} value={'admin'}>{t('user-type-admin')}</MenuItem>
                    <MenuItem key={'type-owner'} value={'owner'}>{t('user-type-owner')}</MenuItem>
                    <MenuItem key={'type-controlling'} value={'controlling'}>{t('user-type-controlling')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {cities?.length > 0 && ['owner','controlling'].indexOf(values.type) > -1 && <>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="city-select-label">{t('City')}</InputLabel>
                    <Select
                      labelId="city-select-label"
                      id="city-select"
                      value={values?.cityId ?? ''}
                      label={t('City')}
                      onChange={handleChange('cityId')}
                    >
                      <MenuItem key={'city-not-set'} value={''}>{t('All')}</MenuItem>
                      { cities.map(city => (<MenuItem key={`city-${city.id}`} value={city.id}>{city.name}</MenuItem>)) }
                    </Select>
                  </FormControl>
                </>}
              </Grid>
              </>}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch
                    checked={showPasswordPanel}
                    onChange={(e) => setShowPasswordPanel(!showPasswordPanel)}
                    disabled={isNaN(userId)}
                  />}
                  label={t('Set password')} />
              </Grid>
              { showPasswordPanel && <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="user-password">{t('Password')}</InputLabel>
                    <Input
                      id="user-password"
                      type={'password'}
                      value={values.password}
                      onChange={handleChange('password')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="user-password-confirm">{t('Confirm password')}</InputLabel>
                    <Input
                      id="user-password-confirm"
                      type={'password'}
                      value={values.confirmPassword}
                      onChange={handleChange('confirmPassword')}
                    />
                  </FormControl>
                </Grid>
              </>}
            </Grid>
          </Paper>
          <Box sx={{ mt: 2, px: 1 }}>
            <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
            { !isNaN(userId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }} onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>) }
          </Box>
        </Grid>
        <AlertSimpleDialog
          title={t('Deleting user')}
          message={t('delete-confirm-msg', { name: values.name })}
          open={shownDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />
      </Container>
    </Page>
  )
}