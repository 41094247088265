import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid, IconButton,
  Input, InputAdornment,
  InputLabel, MenuItem,
  Paper, Select,
  Switch,
}                                 from '@mui/material'
import { useTranslation }         from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useApi }                 from '../utils/api'
import { useSnackbar }            from 'notistack'
import AlertSimpleDialog          from '../ui/components/alert-simple-dialog'
import Page                       from '../ui/components/Page'
import Iconify                    from '../ui/components/Iconify'
import CopyToClipboardWidget      from '../ui/components/CopyToClipboardWidget'
import { BaseBreadcrumbs }        from '../ui/components/BaseBreadcrumbs'

export default function AppAccessPage() {

  const api = useApi()
  const navigate = useNavigate()
  const { appAccessId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: '',
    note: '',
    token: '',
    userId: '',
  })
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    (async () => {
      if (!isNaN(appAccessId)) {
        let appAccess = await api.appAccess.detail(appAccessId)
        setValues({
          name: '',
          type: '',
          token: '',
          userId: '',
          ...appAccess
        })
      }
    })()
  }, [appAccessId])
  useEffect(() => {
    api.user.list(null, ['owner', 'controlling'], 0, 1000).then(({ data }) => setUsers(data))
  }, [])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.appAccess.delete(appAccessId).then(() => {
        enqueueSnackbar(t('App access deleted'), {
          variant: 'success'
        })
        navigate('/app-accesses')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete app access'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = { ...values }
    try {
      let appAccess = await api.appAccess.save(data)
      navigate(`/app-accesses/${appAccess.id}`)
      enqueueSnackbar(t('App access saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save app access'), {
        variant: 'error'
      })
    }
  }

  return (
    <Page title={t('App access')}>
      <Container container component="main">
        <BaseBreadcrumbs key={'breadcrumb-accesses'}
                         title={values?.name ?? t('App access')}
                         items={[
                           { title: t('App accesses'), href: `/app-accesses` }
                         ]}/>
        <Grid component={'form'} onSubmit={onSubmit} item xs={12}>
          <Paper sx={{ p: 2, width: '100%' }}>
            <Grid container sx={{ width: '100%' }} spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="app-access-name">{t('Name')}</InputLabel>
                  <Input
                    id="app-access-name"
                    value={values?.name ?? ''}
                    onChange={handleChange('name')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="app-access-note">{t('Note')}</InputLabel>
                  <Input
                    id="app-access-note"
                    value={values?.note ?? ''}
                    onChange={handleChange('note')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="app-access-token">{t('Token')}</InputLabel>
                  <Input
                    id="app-access-token"
                    value={values?.token ?? ''}
                    disabled={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <CopyToClipboardWidget value={values.token} />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              {users?.length > 0 && <>
                <Grid item xs={12}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="app-access-user-label">{t('User')}</InputLabel>
                    <Select
                      labelId="app-access-user-label"
                      id="app-access-select"
                      value={values?.userId ?? ''}
                      label={t('User')}
                      onChange={handleChange('userId')}
                    >
                      <MenuItem key={'type-none'} value={''}>{t('None')}</MenuItem>
                      {users.map(it => (<MenuItem key={`user-${it.id}`} value={it.id}>
                        <span>{it.name}</span>&nbsp;|&nbsp;<em>{it.type}</em>
                      </MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
              </>}
            </Grid>
          </Paper>
          <Box sx={{ mt: 2, px: 1 }}>
            <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
            { !isNaN(appAccessId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }} onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>) }
          </Box>
        </Grid>
        <AlertSimpleDialog
          title={t('Deleting app access')}
          message={t('delete-confirm-msg', { name: values.name })}
          open={shownDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />
      </Container>
    </Page>
  )
}