import { fDateTime }      from '../utils/textutils'
import { useSnackbar }    from 'notistack'
import { IconButton }     from '@mui/material'
import Iconify            from '../ui/components/Iconify'
import * as React         from 'react'
import { useApi }         from '../utils/api'
import { useTranslation } from 'react-i18next'

export default function PrintTicketWidget({ ticket }) {

  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()
  const { t } = useTranslation()

  const download = () => {
    api.customer.download(ticket.id).then(blob => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${ticket.telNumber.replace(/\D/g, '')}-${fDateTime(ticket.created, 'yyyy-MM-dd_HH:mm')}.pdf`)
      document.body.appendChild(link)
      link.click()
      // Clean up and remove the link
      link.parentNode.removeChild(link)
    }).catch(e => {
      enqueueSnackbar(t('Failed to download ticket'), {
        variant: 'error'
      })
    })
  }

  const print = () => {
    api.customer.download(ticket.id).then(blob => {
      // Create blob iframe to print
      const file = new Blob([blob], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      const iframe = document.createElement('iframe')
      iframe.src = fileURL
      iframe.style.display = 'none'
      // Clean up and remove the iframe
      iframe.onload = () => {
        const iframeWindow = iframe.contentWindow || iframe
        iframe.focus();
        iframeWindow.print()
      }
      document.body.appendChild(iframe)
    }).catch(e => {
      console.log(e)
      enqueueSnackbar(t('Failed to print ticket'), {
        variant: 'error'
      })
    })
  }

  return <>
    <IconButton size={'small'} color={'secondary'} onClick={(e)=> {
      download()
    }}>
      <Iconify icon={'material-symbols:download'} />
    </IconButton>
    <IconButton size={'small'} color={'success'} onClick={(e)=> {
      print()
    }}>
      <Iconify icon={'material-symbols:print'} />
    </IconButton>
  </>
}