import { useRef, useState } from 'react'
// material
import { styled, alpha }                                                       from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
// component
import Iconify                                                                 from '../../components/Iconify';
import { useTranslation }                                                      from 'react-i18next'
import { useNavigate }                                                         from 'react-router-dom'

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyleForm = styled('form')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate()
  const queryInputRef = useRef(null)

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)
    setOpen(false)
    queryInputRef.current.value = ''
    navigate(`/search/${encodeURIComponent(data.get('query'))}`)
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyleForm onSubmit={onSubmit}>
            <Input
              ref={queryInputRef}
              autoFocus
              fullWidth
              disableUnderline
              name={'query'}
              placeholder={t('SearchForPlaceholder')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" type={'submit'}>
              {t('SearchAction')}
            </Button>
          </SearchbarStyleForm>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
