import * as React         from 'react'
import { useTranslation } from 'react-i18next'
import { useApi }         from '../utils/api'
import { useSnackbar }    from 'notistack'
import { IconButton }     from '@mui/material'
import AlertSimpleDialog  from '../ui/components/alert-simple-dialog'
import { fDateTime }      from '../utils/textutils'
import Iconify            from '../ui/components/Iconify'

export default function CityServiceChangeDeleteDialog({ csChange, onResult }) {

  const api = useApi()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (result) => {
    setOpen(false)
    if (!!onResult) {
      onResult(result)
    }
  };

  return (
    <React.Fragment>
      <IconButton size={'small'} color={'error'} onClick={handleClickOpen}>
        <Iconify icon={'material-symbols:delete'}/>
      </IconButton>
      <AlertSimpleDialog
        title={t('Delete scheduled change')}
        message={t('delete-scheduled-change-confirm', { scheduled: fDateTime(csChange.scheduled) })}
        open={open}
        onClose={async (result) => {
          if (!result){
            handleClose(result)
            return
          }
          api.cityService.deleteCityServiceChange(csChange.id).then(() => {
            enqueueSnackbar(t('City service change deleted'), {
              variant: 'success'
            })
          }).catch(error => {
            enqueueSnackbar(t('Failed to delete city service change'), {
              variant: 'error'
            })
          }).finally(() => {
            handleClose(result)
          })
        }}
      />
    </React.Fragment>
  );
}