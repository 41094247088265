import PropTypes                         from 'prop-types'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useTranslation }                from 'react-i18next'
import { Link as RouterLink }            from 'react-router-dom'

export const BaseBreadcrumbs = ({ items, title, ...others }) => {

  const { t } = useTranslation()

  return <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }} {...others}>
    <Link underline="hover" color="inherit" to="/" component={RouterLink}>{t('Home')}</Link>
    {(items ?? []).map((it, index) => {
      return (
        <Link
          key={`link-${index}`}
          component={RouterLink}
          underline="hover"
          color="inherit"
          to={it.href}
        >
          {it.title}
        </Link>
      )
    })}
    {title && <Typography color="text.primary">{title}</Typography>}
  </Breadcrumbs>
}
BaseBreadcrumbs.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
  })),
}