import React, { useState }       from 'react'
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography
}                                from '@mui/material'
import { useApi }                from '../utils/api'
import { Link as RouterLink }    from 'react-router-dom'
import { useTranslation }        from 'react-i18next'
import Page                      from '../ui/components/Page'
import BaseTable, { BaseSearch } from '../ui/components/base-table'
import Iconify                   from '../ui/components/Iconify'
import { BaseBreadcrumbs }       from '../ui/components/BaseBreadcrumbs'

export default function AppAccessesPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'name', headerName: t('Name'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/app-accesses/${cell.row.id}`}
        >
          {cell.row.name}
        </Link>
      )},
    { field: 'note', headerName: t('Note'), flex: 1, sortable: false, filterable: false },
    { field: 'city', headerName: t('City'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        cell.row.user?.city?.id ?
          <Link
            component={RouterLink}
            variant="body2"
            to={`/cities/${cell.row.user.city.id}`}
          >
            {cell.row.user.city.name}
          </Link> : '---'
      )},
    { field: 'user', headerName: t('User'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        cell.row.user?.id ?
          <Link
            component={RouterLink}
            variant="body2"
            to={`/users/${cell.row.user.id}`}
          >
            {cell.row.user.name}
          </Link> : '---'
      )},
  ];

  return (<Page title={t('App accesses')}>
    <Container>
      <BaseBreadcrumbs key={'breadcrumb-accesses'}
                       title={t('App accesses')}
                       items={[]}/>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('App accesses')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/app-accesses/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New app access')}
        </Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.appAccess.list(query, page, pageSize)
          } catch (e) {
            console.log('Failed to load app accesses', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}