import { DateTime }              from 'luxon'

const fmVisibleDatetimeFull = 'dd.MM.yyyy HH:mm:ss'
export const fmVisibleDateTime = 'dd.MM.yy HH:mm'
export const fmVisibleTime = 'HH:mm:ss'
export function fDateTime(dateTimeStr, newFormat) {
  const fm = newFormat || fmVisibleDatetimeFull;
  return DateTime.fromISO(dateTimeStr).setZone('local').toFormat(fm)
}
export function fDateFromTo(from, to, fmDefault = fmVisibleDateTime, fmShorten = 'HH:mm') {
  const parsedFrom = DateTime.fromISO(from).setZone('local')
  const parsedTo = DateTime.fromISO(to).setZone('local')
  const isSameDay = parsedFrom.hasSame(parsedTo, 'day')
  const isToday = isSameDay && DateTime.now().hasSame(parsedFrom, 'day')
  return `${parsedFrom.toFormat(isToday ? fmShorten : fmDefault)} - ${parsedTo.toFormat(isToday || isSameDay ? fmShorten : fmDefault)}`
}
export function fDateTimeAuto(dateTimeStr) {
  const datetime = DateTime.fromISO(dateTimeStr)
  if (datetime.hasSame(DateTime.now(), 'days')) {
    return datetime.setZone('local').toFormat('HH:mm')
  }
  if (datetime.hasSame(DateTime.now(), 'year')) {
    return datetime.setZone('local').toFormat('LLL d, HH:mm')
  }
  return datetime.setZone('local').toFormat(fmVisibleDateTime)
}
export function fPrice(price) {
  const fmtdPrice = (price / 100).toFixed(2)
  return new Intl.NumberFormat('sk-SK', { style: 'currency', currency: 'EUR' }).format(fmtdPrice)
}
export function fExportPrice(price) {
  return price / 100
}
export function fOperator(operator) {
  return {
    23101: 'Orange',
    23102: 'Telekom',
    23106: 'O2',
    23103: 'SWAN',
  }[parseInt(operator)] ?? operator
}
export function fOperatorClass(operator) {
  return {
    23101: 'Orange',
    23102: 'Telekom',
    23106: 'O2',
    23103: 'Swan',
  }[parseInt(operator)] ?? operator
}