import * as React               from 'react'
import Button                   from '@mui/material/Button'
import TextField                from '@mui/material/TextField'
import Link                     from '@mui/material/Link'
import Grid                     from '@mui/material/Grid'
import Box                      from '@mui/material/Box'
import Typography               from '@mui/material/Typography'
import Container                from '@mui/material/Container'
import { useApi }               from '../utils/api'
import { useNavigate }          from 'react-router-dom'
import { Alert, Card, Stack }   from '@mui/material'
import { useContext, useState } from 'react'
import { ParkingAppContext }    from '../App'
import { useTranslation }       from 'react-i18next'
import Page                     from '../ui/components/Page'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© Technicky prevádzku predaja SMS cestovných lístkov zabezpečuje spoločnosť '}
      <Link color="inherit" href="https://mediatex.sk/">
        Mediatex s.r.o.
      </Link>{' - '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function CustomerSignInPage() {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const api = useApi()
  const {phone, setPhone} = useContext(ParkingAppContext)
  const [loginError, setLoginError] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const phone = data.get('phone')
    const code = data.get('code')
    api.customer.login(phone, code).then(() => {
        setPhone(`${phone}:${code}`)
        navigate('/')
      })
      .catch(error => {
        setLoginError(t('CustomerFailedToLogin'))
      })
  };

  return (
    <Page title={t('SMS MHD tickets')}>
      <Container component="main" sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Card color={'background.paper'} sx={{ p:3, mt: { xs: 1, md: 3 } }}>
          <Grid container={true}>
            <Grid item xs={12} md={6}>
              <Stack direction={'column'} alignItems={'center'} sx={{ mt: 2 }}>
                <Typography variant={'body2'} sx={{ my: 1 }}>{t('CustomerSignInTitle')}</Typography>
                <Typography variant={'h4'} sx={{ mb: 4 }}>{t('SMS transport tickets')}</Typography>
                <Box
                  component="img"
                  sx={{
                    mb: { xs: 2, md: 5 },
                  }}
                  alt="Mediatex Parking Logo"
                  src={process.env.PUBLIC_URL+"/static/logo_250_55.png"}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction={'column'}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <Typography variant={'caption'}>{t('CustomerSignInDesc')}</Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('Your phone number')}
                    name="phone"
                    type={'phone'}
                    autoComplete="phone"
                    onKeyDown={(e) => setLoginError(null)}
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="code"
                    label={t('Code')}
                    type="text"
                    id="code"
                    onKeyDown={(e) => setLoginError(null)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {t('Sign In')}
                  </Button>
                  {loginError && <Alert severity="error">{t('CustomerFailedToLogin')}</Alert>}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </Page>
  );
}