import React, { Suspense, useEffect } from 'react'
import './App.css'
import Router                         from './routes'
import { SnackbarProvider }           from 'notistack'
import ThemeProvider                               from './ui/theme'
import { useApi, useCurrentPhone, useCurrentUser } from './utils/api'
import ScrollToTop                                 from './ui/components/ScrollToTop'

export const ParkingAppContext = React.createContext({
  user: null,
});

function App () {

  const api = useApi()
  const [user, setUser] = useCurrentUser()
  const [phone, setPhone] = useCurrentPhone()

  useEffect(() => {
    if (user !== null) {
      api.user.validateHash().then(validateUser => { setUser({
          ...validateUser,
          accessToken: user.accessToken
        }) })
        .catch(err => {
          setUser(null)
        })
    }
  }, [])

  return (<Suspense fallback="loading">
    <ScrollToTop />
    <ThemeProvider>
      <ParkingAppContext.Provider value={{ user: user, setUser: setUser, phone: phone, setPhone: setPhone }}>
        <SnackbarProvider>
          <Router/>
        </SnackbarProvider>
      </ParkingAppContext.Provider>
    </ThemeProvider>
  </Suspense>)
}

export default App
