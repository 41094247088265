import { Link as RouterLink }                                                  from 'react-router-dom'
import BaseTable, { BaseSearch }                                                          from '../ui/components/base-table'
import {
  Autocomplete,
  Box,
  Button,
  Container, FormControl,
  Grid,
  InputLabel,
  Link, MenuItem, Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import Page                                                                               from '../ui/components/Page'
import Iconify                                                                 from '../ui/components/Iconify'
import { useTranslation }             from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../utils/api'
import { BaseBreadcrumbs }                                                     from '../ui/components/BaseBreadcrumbs'
import ShortFormControl
                                                                               from '../ui/components/short-form-control'

export default function CityServicesPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [reloadCounter, setReloadCounter] = useState(0)

  useEffect(() => {
    api.city.list().then(({data}) => {
      setCities(data)
    }).catch((e) => {
      console.error('Failed to load cities' ,e)
    })
  }, [])

  useEffect(() => {
    setReloadCounter((prev) => prev + 1)
  }, [selectedCity])

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'name', headerName: t('Title'), flex: 2, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            variant="body2"
            to={`/city-services/${cell.row.id}`}
          >
            {cell.row.name}
          </Link>
        )
      }},
    { field: 'city', headerName: t('City'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            variant="body2"
            to={`/cities/${cell.row.city.id}`}
          >
            {cell.row.city.name}
          </Link>
        )
      }},
  ];

  return (<Page title={t('City services')}>
    <Container>
      <BaseBreadcrumbs key={'breadcrumb-services'}
                       title={t('City services')}
                       items={[]} />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h4" gutterBottom>{t('City services')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/city-services/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New city service')}
        </Button>
      </Stack>
      <Grid container direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'} mb={2}>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="city-select-label">{t('City')}</InputLabel>
            <Select
              labelId="city-select-label"
              id="city-select"
              value={selectedCity ?? null}
              label={t('City')}
              onChange={(e) => setSelectedCity(e.target.value)}
            >
              <MenuItem key={'city-not-set'} value={''}>{t('All')}</MenuItem>
              { cities.map(city => (<MenuItem key={`city-${city.id}`} value={city.id}>{city.name}</MenuItem>)) }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <BaseTable
        columns={columns}
        query={query}
        forceReloadCounter={reloadCounter}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.cityService.list(selectedCity, query, page, pageSize)
          } catch (e) {
            console.log('Failed to load city services', e)
            return {
              data: [],
              totalCount: 0,
            }
          }
        }}
      />
    </Container>
  </Page>)

}