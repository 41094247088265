import * as React                                                                  from 'react'
import Button                                                                      from '@mui/material/Button'
import Dialog                                                                      from '@mui/material/Dialog'
import DialogActions                                                               from '@mui/material/DialogActions'
import DialogContent                                                               from '@mui/material/DialogContent'
import DialogTitle                                                                 from '@mui/material/DialogTitle'
import { useTranslation }                                                          from 'react-i18next'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function CityServiceChangeDialog({ object, changedObject }) {

  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDiffs = (original, changed, prefix = '') => {
    if (!original || !changed) {
      if (original || changed) {
        return getDiffs(original ?? {}, changed ?? {}, prefix)
      }
      return []
    }
    const result = []
    for (const key in (Object.keys(original).length === 0 ? changed : original)) {
      if (original[key] === changed[key] || ['created','modified'].indexOf(key) !== -1) {
        continue
      }
      if (Array.isArray(original[key])) {
        for (let i = 0; i < original[key].length; i++) {
          result.push(...getDiffs(original[key][i], changed[key][i], `${prefix}${key}.${i}.`))
        }
        continue
      }
      if (typeof original[key] === 'boolean') {
        result.push({ key: `${prefix}${key}`, oldVal: original[key].toString(), newVal: changed[key].toString() })
        continue
      }
      if (typeof original[key] === 'object') {
        result.push(...getDiffs(original[key], changed[key], `${prefix}${key}.`))
        continue
      }
      result.push({ key: `${prefix}${key}`, oldVal: original[key], newVal: changed[key] })
    }
    return result
  }

  return (
    <React.Fragment>
      <Button type={'button'} size={'small'} onClick={handleClickOpen}>
        {t('Show changes')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t('Scheduled changes')}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('ChangeKey')}</TableCell>
                  <TableCell align="right">{t('ChangeOldValue')}</TableCell>
                  <TableCell align="right">{t('ChangeNewValue')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getDiffs(object, changedObject).map((row) => {
                  return (
                    <TableRow
                      key={`row-${row.key}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.key}
                      </TableCell>
                      <TableCell align="right">{row.oldVal}</TableCell>
                      <TableCell align="right">{row.newVal}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}